import fr from '@/translations/fr.json';
import en from '@/translations/en.json';
import { createI18n } from 'vue-i18n';

const translations = {
    fr,
    en,
};

const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages: translations,
    legacy: false,
});

export default i18n;
