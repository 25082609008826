<script setup lang="ts">
import HeaderLayout from '@/layouts/HeaderLayout.vue';
import RouteNameEnum from '@/router/routeNameEnum';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import router from '@/router';
import useAuthStore from '@/domains/authentication/store/auth.store';
import { logout } from '@/domains/authentication/services/authenticate';
import IconArrowTopRightSquare from '@/domains/common/components/icons/IconArrowTopRightSquare.vue';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import MenuDropdown from '@/domains/common/components/MenuDropdown.vue';
import TheSelectOfficeHeader from '@/domains/authentication/components/TheSelectOfficeHeader.vue';
import BaseButtonRouterLink from '@/domains/common/components/BaseButtonRouterLink.vue';
import BaseLink from '@/domains/common/components/BaseLink.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const authStore = useAuthStore();

const { isUserAuthorized } = authStore;

const allMyOfficeMenuItems: { title: string; name: RouteNameEnum }[] = [
    {
        title: t('menu.user', 2),
        name: RouteNameEnum.MyOfficeUser,
    },
    {
        title: t('menu.infos'),
        name: RouteNameEnum.MyOfficeEdit,
    },
];

const elementsMyOfficeSubmenu = computed(() => {
    return filterAllowedMenuItems(allMyOfficeMenuItems);
});

function filterAllowedMenuItems(items: { title: string; name: RouteNameEnum }[]) {
    const allowedMenuItems = items.filter((item) => {
        const routeResult = router.getRoutes().find((r) => r.name === item.name);

        if (!routeResult) {
            return false;
        }

        return isUserAuthorized((routeResult.meta.roles as any[]) || []);
    });

    return allowedMenuItems;
}

function isSubmenuActive(basePath: string) {
    return router.currentRoute.value.path.startsWith(basePath);
}
</script>

<template>
    <HeaderLayout>
        <template #navSection="{ isMenuDisabled }">
            <BaseButtonRouterLink
                :to="{ name: RouteNameEnum.Patients }"
                :class="[
                    'transition-colors duration-300 ease-in-out',
                    isMenuDisabled && 'pointer-events-none opacity-50',
                ]"
                active-class="!text-primary !font-bold"
                :color="ButtonColorEnum.Menu"
                :label="$t('menu.patients')"
            />
            <BaseButtonRouterLink
                :to="{ name: RouteNameEnum.Orders }"
                :class="[
                    'px-4 font-semibold transition-colors duration-300 ease-in-out',
                    isMenuDisabled && 'pointer-events-none opacity-50',
                ]"
                active-class="!text-primary !font-bold"
                :color="ButtonColorEnum.Menu"
                :label="$t('menu.orders')"
            />
        </template>
        <template #menuSection="{ isMenuLarge, isMenuDisabled }">
            <div :class="[isMenuLarge ? 'pr-0' : 'pr-14', 'flex items-center']">
                <MenuDropdown :class="[isMenuDisabled && 'pointer-events-none opacity-50', 'min-w-32']">
                    <template #buttonBody>
                        <span :class="isSubmenuActive('/settings') && 'font-bold text-primary'">{{
                            $t('menu.my_account')
                        }}</span>
                    </template>
                    <template #dropdownList>
                        <BaseButtonRouterLink
                            :to="{ name: RouteNameEnum.Settings }"
                            :class="[
                                'px-4 font-semibold transition-colors duration-300 ease-in-out',
                                isMenuDisabled && 'pointer-events-none opacity-50',
                            ]"
                            active-class="!text-primary !font-bold"
                            :color="ButtonColorEnum.Menu"
                            :label="$t('menu.parameters')"
                        />
                        <BaseButton
                            :label="$t('menu.logout')"
                            :color="ButtonColorEnum.Menu"
                            @click="logout({ shouldLoginRedirect: true })"
                        />
                    </template>
                </MenuDropdown>

                <MenuDropdown
                    v-if="elementsMyOfficeSubmenu.length"
                    :class="[isMenuDisabled && 'pointer-events-none opacity-50', 'min-w-32']"
                >
                    <template #buttonBody>
                        <span :class="isSubmenuActive('/my-office') && 'font-bold text-primary'">
                            {{ $t('menu.my_office') }}
                        </span>
                    </template>
                    <template #dropdownList>
                        <BaseButtonRouterLink
                            v-for="el in elementsMyOfficeSubmenu"
                            :key="el.name"
                            :to="{ name: el.name }"
                            class="px-4 py-2"
                            :color="ButtonColorEnum.Menu"
                            active-class="!text-primary !font-bold"
                            :label="el.title"
                        />
                    </template>
                </MenuDropdown>
                <BaseLink
                    link="https://www.ergorecherche.fr/aide_podofit/"
                    :class="[
                        'px-4 font-semibold transition-colors duration-300 ease-in-out hover:text-secondary',
                        isMenuDisabled && 'pointer-events-none opacity-50',
                    ]"
                    :color="ButtonColorEnum.Menu"
                    :label="$t('menu.help')"
                    :icon="IconArrowTopRightSquare"
                />
            </div>
            <TheSelectOfficeHeader :expanded-menu="isMenuLarge" />
        </template>
    </HeaderLayout>
</template>
