import { arraysHaveCommonItems } from '@/utils/arrays';
import { getUserProfile, updateCurrentOffice } from '@/domains/authentication/api/authentication.api';
import { handleRenderError } from '@/domains/common/services/errorHandler';
import RoleEnum from '@/domains/authentication/typescript/RoleEnum';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type OfficeInterface from '@/domains/office/typescript/OfficeInterface';
import type UserInterface from '@/domains/authentication/typescript/UserInterface';
import type UserOfficeInterface from '@/domains/authentication/typescript/UserOfficeInterface';
import type UserProfileInterface from '@/domains/authentication/typescript/UserProfileInterface';

export default defineStore('auth', () => {
    const selectedUserOffice = ref<OfficeInterface | null>(null);
    const isLoadingUser = ref(true);
    const isUserLoggedIn = ref(false);
    const userId = ref<string>();
    const userRootRoles = ref<RoleEnum[]>([]);
    const userOffices = ref<UserOfficeInterface[]>([]);
    const userProfile = ref<UserProfileInterface | null>(null);

    const isAdminErgo = computed(() => isGranted(RoleEnum.ROLE_ADMIN_ERGO));

    const updateUserProfile = async (userOffice: UserOfficeInterface): Promise<void> => {
        if (userOffice) {
            const officeUserDTO = {
                user: `/users/${userId.value}`,
                office: `/offices/${userOffice.office.id}`,
            };
            await updateCurrentOffice(userOffice.id, officeUserDTO);
            userProfile.value = await getUserProfile(userOffice.id);
            selectedUserOffice.value = userOffice.office;
        }
    };

    const initUserInfos = async (payload: UserInterface): Promise<void> => {
        userRootRoles.value = payload?.roles ?? [];
        userId.value = payload?.id ?? undefined;
        if (isGranted(RoleEnum.ROLE_ADMIN_ERGO)) {
            return;
        }
        userOffices.value = payload?.officeUsers ?? undefined;

        if (userOffices.value !== undefined && userOffices.value.length > 0) {
            let userOffice: UserOfficeInterface;

            if (payload.currentOfficeUser) {
                selectedUserOffice.value = payload.currentOfficeUser.office;
            }

            if (userOffices.value.length === 1) {
                userOffice = userOffices.value[0];
            } else {
                const selectedOffice = userOffices.value?.find((office) => {
                    return office.office.id === selectedUserOffice.value?.id;
                });
                userOffice = selectedOffice ?? userOffices.value[0];
            }
            updateUserProfile(userOffice);
            return;
        }

        console.error('No office found for user', payload.id);
        handleRenderError(new Error('auth.error.no_office'), null, 'auth.error.no_office');
        Promise.reject();
    };

    function getUserOfficeById(officeId: string) {
        return userOffices.value.find((office) => office.office.id === officeId);
    }

    const offices = computed<OfficeInterface[]>(() => {
        return userOffices.value.map((user) => {
            return user.office;
        });
    });

    function mergeUserOfficeRolesInUserRoles(userRoles: RoleEnum[], userOfficeRoles: RoleEnum[]): RoleEnum[] {
        const roles: RoleEnum[] = [...userRoles];

        userOfficeRoles.forEach((role) => {
            if (!roles.includes(role)) {
                roles.push(role);
            }
        });

        return roles;
    }

    const roles = computed<RoleEnum[]>(() => {
        if (userProfile.value !== null) {
            return mergeUserOfficeRolesInUserRoles(userRootRoles.value, userProfile.value.roles);
        }

        return userRootRoles.value;
    });

    // used to conditionally display UI elements
    const isUserAuthorized = (rolesNeeded: RoleEnum[] = []) => {
        if (!rolesNeeded.length) {
            return true;
        }
        return arraysHaveCommonItems(rolesNeeded, roles.value);
    };

    const isGranted = (role: RoleEnum) => {
        return roles.value.includes(role);
    };

    function $reset() {
        console.info('reset auth store');
        selectedUserOffice.value = null;
        isUserLoggedIn.value = false;
        userId.value = undefined;
        userProfile.value = null;
        userRootRoles.value = [];
        userOffices.value = [];
    }

    return {
        selectedUserOffice,
        isUserLoggedIn,
        isLoadingUser,
        userId,
        userProfile,
        isUserAuthorized,
        initUserInfos,
        updateUserProfile,
        getUserOfficeById,
        roles,
        offices,
        isGranted,
        isAdminErgo,

        $reset,
    };
});
