import ScanController from '@/3d-app/scan/ScanController';
import NavigationController from '@/3d-app/navigation/NavigationController';
import FootSideEnum from '@/domains/scan/typescript/enums/FootSideEnum';
import ScreenshotManager from '@/3d-app/scan/ScreenshotManager';
import ImportExportManager from '@/3d-app/scan/ImportExportManager';
import ScanWorld from '@/3d-app/scan/ScanWorld';
import { Vector3 } from 'three';
import type ScanFilesBlobInterface from '@/domains/scan/typescript/interfaces/ScanFilesBlobInterface';
import type TElementParams from '@/3d-app/scan/elementPlacement/TElementParams';

const scanAPI = {
    initCanvas(container: HTMLDivElement, mainCanvas: HTMLCanvasElement, navCanvas: HTMLCanvasElement): void {
        // Navigation canvas
        NavigationController.initialize({
            container: navCanvas,
            webGLParameters: {
                canvas: navCanvas,
                antialias: true,
                alpha: true,
            },
        });

        // General 3D canvas
        // eslint-disable-next-line no-new
        new ScanController({
            container,
            webGLParameters: {
                canvas: mainCanvas,
                antialias: false,
                logarithmicDepthBuffer: true, // To prevent geometry glitches
            },
        });
    },

    homeCamera(): void {
        ScanController.instance?.camera?.homeCamera(
            ScanController.footSize * ScanController.SCALE_TO_M_FACTOR * ScanWorld.HOME_CAMERA_DISTANCE,
            new Vector3(0, ScanWorld.Y_OFFSET_CAMERA * ScanController.footSize, 0),
        );
    },

    placeAlignPoints(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.toggleMode(modeManager.alignPointMode);
        }
    },

    moveScan(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.toggleMode(modeManager.alignScanMode);
        }
    },

    moveSole(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.toggleMode(modeManager.alignSoleMode);
        }
    },

    setScanOpacity(opacity: number): void {
        ScanController.instance?.setScanOpacity(opacity);
    },

    setSoleOpacity(opacity: number): void {
        ScanController.instance?.setSoleOpacity(opacity);
    },

    setSoleVisible(isVisible: boolean): void {
        ScanController.instance?.setSoleVisible(isVisible);
    },

    setTemplateVisible(isVisible: boolean): void {
        ScanController.instance?.setTemplateVisible(isVisible);
    },

    getIsOtherFootVisible(): boolean {
        return ScanController.instance?.isOtherFootVisible ?? true;
    },

    /**
     * Defines wether the other foot (sole+scan) will be visible not active
     * @param isVisible true for visible, false for invisible
     */
    setIsOtherFootVisible(isVisible: boolean): void {
        if (ScanController.instance) {
            ScanController.instance.isOtherFootVisible = isVisible;
        }
    },

    /**
     * Toggle scan points visibility on or off
     * @param isVisible true for visible points, false for invisible
     */
    setScanPointsVisible(isVisible: boolean): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.alignPointMode.setPointsVisibility(isVisible);
        }
    },

    /**
     * Change opacity of selected element.
     * If no element is selected, future selection will have this opacity.
     * @param opacity new opacity of element (1 fully opaque, 0 fully transparent)
     */
    setElementOpacity(opacity: number): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.elementMode.elementOpacity = opacity;
        }
    },

    setCurrentFoot(footSide: FootSideEnum): void {
        const controller = ScanController.instance;
        if (controller) {
            if (footSide === FootSideEnum.Right) {
                controller.isRightFoot = true;
            } else {
                controller.isRightFoot = false;
            }
            controller.updateSelectedFoot();
        }
    },

    elementMode(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.activateMode(modeManager.elementMode);
        }
    },

    defaultMode(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.deactivateCurrentMode();
        }
    },

    /**
     * Symmetrize an element
     * This will also change the current sole selected, to focus new element.
     * The element won't be symmetrized if the symmetrized element is already on the other sole (same position, rotation and scale).
     * @param elementId unique identifier of the element
     * @returns a promise containing the id of the added element, rejected if it can't be symmetrized or the id does not exists
     */
    symmetrizeElement(elementId: number): Promise<number> {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            return modeManager.elementMode.symmetrizeElement(elementId);
        }
        return Promise.reject(new Error('ScanController not ready!'));
    },

    loadScan(scan: ScanFilesBlobInterface, flip: boolean = true): void {
        ScanController.instance?.loadScan(scan, flip);
    },

    load2DScan(png: Blob, dpi: number, flip: boolean = true): void {
        ScanController.instance?.load2dScan(png, dpi, flip);
    },

    update2DScanImage(png: Blob): void {
        ScanController.instance?.currentScanMesh.update2dScan(png);
    },

    get2DSoleTranforms(): {
        scale: number;
        position: { x: number; y: number };
        rotation: { x: number; z: number };
    } {
        if (!ScanController.instance) {
            throw new Error('ScanController not ready!');
        }
        const scan = ScanController.instance.currentScanMesh;
        const template = ScanController.instance.currentTemplateMesh;

        const scanWorldPos = new Vector3();
        scan.scanGeometry.getWorldPosition(scanWorldPos);

        const templateOrigin = new Vector3();
        template.mesh.getWorldPosition(templateOrigin);

        const centerOffsets = scanWorldPos.sub(templateOrigin);

        return {
            scale: ScanController.footSize,
            position: {
                x: centerOffsets.x,
                y: centerOffsets.y,
            },
            rotation: { x: scan.mesh.rotation.x, z: scan.mesh.rotation.z },
        };
    },

    hasLeftScan() {
        return !!ScanController.instance?.hasScan(false);
    },

    hasRightScan() {
        return !!ScanController.instance?.hasScan(true);
    },

    /**
     * Load or reload the left sole model
     * @param sole ArrayBuffer constaining the 3dm of the sole
     * @returns a promise that is resolved when the sole is loaded and added to the scene
     */
    loadLeftSole(sole: ArrayBuffer): Promise<null> {
        return ScanController.instance?.loadSole(sole, false) ?? Promise.reject(new Error('ScanController not ready!'));
    },

    /**
     * Load or reload the right sole model
     * @param sole ArrayBuffer constaining the 3dm of the sole
     * @returns a promise that is resolved when the sole is loaded and added to the scene
     */
    loadRightSole(sole: ArrayBuffer): Promise<null> {
        return ScanController.instance?.loadSole(sole, true) ?? Promise.reject(new Error('ScanController not ready!'));
    },

    /**
     * Add an element on the sole.
     * When done, the id of the added element will be returned in the promise
     * @param element the element to add, a 3dm as ArrayBuffer
     * @param params the element parameters
     * @param isRightSole specify the parent sole (leave empty for current sole)
     * @param canMove set to false to prevents the element to be moved (true by default)
     * @param canRotate set to false to prevents the element to be resized (true by default)
     * @param canScale set to false to prevents the element to be resized (true by default)
     * @returns a promise containing the id of the added element
     */
    addElement(
        element: ArrayBuffer,
        params: TElementParams,
        isRightSole: boolean | undefined = undefined,
        canMove: boolean = true,
        canRotate: boolean = true,
        canScale: boolean = true,
    ): Promise<number> {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            return modeManager.elementMode.addElement(element, params, isRightSole, canMove, canRotate, canScale);
        }
        return Promise.reject(new Error('ScanController not ready!'));
    },

    /**
     * Return element infos for export from element id. Will throw error if element is not found.
     * @param elementId unique identifier of the element
     * @param scaled true for scaled values instead of direct ones (to send to Rhino)
     * @returns the element parameters to save
     */
    getElementInfosById(elementId: number, scaled: boolean = false): TElementParams {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            return modeManager.elementMode.getElementInfosById(elementId, scaled);
        }
        throw new Error('ScanController not ready!');
    },

    /**
     * Load or reload the sole model
     * @param sole ArrayBuffer constaining the 3dm of the sole
     * @param isRightSole true is the given sole is the right one (false by default)
     * @returns a promise that is resolved when the sole is loaded and added to the scene
     */
    loadSole(sole: ArrayBuffer, isRightSole: boolean = false): Promise<null> {
        return (
            ScanController.instance?.loadSole(sole, isRightSole) ??
            Promise.reject(new Error('ScanController not ready!'))
        );
    },

    export(): Blob {
        return ImportExportManager.export();
    },

    import(selectedFile: File): Promise<void> {
        return ImportExportManager.import(selectedFile);
    },

    screenshot(): Promise<Blob> {
        return ScreenshotManager.getScreenshot(512, 512);
    },

    /**
     * Select the element identified by Id. Will throw error if element is not found.
     * Will change current foot if element is on other foot
     * @param elementId unique identifier of the element (null to deselect)
     */
    selectElement(elementId: number | null): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.elementMode.selectElementById(elementId);
        }
    },

    /**
     * Unselect the element.
     */
    unselectElement(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.elementMode.selectElementById(null);
        }
    },

    /**
     * Delete the element identified by Id. Will throw error if element is not found.
     * @param elementId unique identifier of the element
     */
    deleteElement(elementId: number): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.elementMode.deleteElementById(elementId);
        }
    },

    getIsRightFoot(): boolean {
        return ScanController.instance?.isRightFoot ?? false;
    },

    updateFootSize(previousSize: number): void {
        ScanController.instance?.updateFootSize(previousSize === 0 ? 42 : previousSize);
    },

    updatePattern(): void {
        ScanController.instance?.updatePattern();
    },

    /**
     * Force recomputing the scan alignment and position
     * @param isRightScan true to update right scan, false for left one
     */
    recomputeScanPosition(isRightScan: boolean): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.alignPointMode.recomputeScanPosition(isRightScan);
        }
    },

    /**
     * Force recomputing the scan alignment and position on both scans
     */
    recomputeScanPositionForBoth(): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.alignPointMode.recomputeScanPosition(false);
            modeManager.alignPointMode.recomputeScanPosition(true);
        }
    },

    /**
     * Compute distance between the arch point and the alignment plane, on specified scan
     * @param {boolean} isRightScan true for right arch distance, false for left
     * @returns the distance, or 0 it the arch point does not exist
     */
    getScanArchHeight(isRightScan: boolean): number {
        if (!ScanController.instance) {
            throw new Error('ScanController not ready!');
        }
        const { modeManager } = ScanController.instance;
        return modeManager.alignPointMode.getScanArchHeight(isRightScan);
    },

    dispose3D(): void {
        ScanController.instance?.dispose();
        NavigationController.dispose();
    },

    setElementOpacityById(elementId: number, opacity: number): void {
        if (ScanController.instance) {
            const { modeManager } = ScanController.instance;
            modeManager.elementMode.setElementOpacityById(elementId, opacity);
        }
    },
};

export default scanAPI;

// window.scanApi = scanAPI; // Uncomment for live testing
