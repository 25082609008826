import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type PatientInterface from '@/domains/patient/typescript/PatientInterface';

export default defineStore('patient', () => {
    const patients = ref<PatientInterface[]>([]);
    const selectedPatient = ref<PatientInterface>();
    const hasPatientSelected = computed<boolean>(() => selectedPatient.value !== undefined);

    const selectPatientById = (patientId: string): void => {
        selectedPatient.value = patients.value.find((patient) => patient.id === patientId);
    };

    const isPatientSelected = (patient: PatientInterface): boolean => selectedPatient.value?.id === patient.id;

    const unselectPatient = (): void => {
        selectedPatient.value = undefined;
    };

    function $reset() {
        console.info('reset patient store');
        patients.value = [];
        selectedPatient.value = undefined;
    }

    return {
        patients,
        selectedPatient,
        hasPatientSelected,
        selectPatientById,
        isPatientSelected,
        unselectPatient,

        $reset,
    };
});
