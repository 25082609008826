import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import { getRhinoLoader } from '@/3d-app/commons/RhinoUtils';
import { Box3, DoubleSide, Group, Line, Material, Mesh, MeshBasicMaterial, Vector2, Vector3 } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

/**
 * Base class of all element meshes
 */
class ElementMesh extends Group {
    static readonly SCALE_TO_CM_FACTOR = 0.1;

    protected _isParentFootRight: boolean;

    protected _meshMaterials: Array<Material>;

    protected _boundingBox: Box3;

    protected _canMove: boolean;

    protected _canRotate: boolean;

    protected _canScale: boolean;

    get canMove(): boolean {
        return this._canMove;
    }

    get canRotate(): boolean {
        return this._canRotate;
    }

    get canScale(): boolean {
        return this._canScale;
    }

    get isParentFootRight(): boolean {
        return this._isParentFootRight;
    }

    set isParentFootRight(isParentFootRight: boolean) {
        this._isParentFootRight = isParentFootRight;
    }

    get boundingBox(): Box3 {
        return this._boundingBox;
    }

    public updateBoundingBox() {
        const savedRotation = this.rotation.z;
        const savedScale = this.scale.clone();
        this.scale.copy(new Vector3(1, 1, 1));
        this.rotation.z = 0;
        this.updateWorldMatrix(false, true);
        this._boundingBox = this.orientedBoundingBox;
        this.rotation.z = savedRotation;
        this.scale.copy(savedScale);
    }

    get orientedBoundingBox(): Box3 {
        return new Box3().setFromObject(this.children[0]);
    }

    constructor(
        isParentFootRight: boolean,
        canMove: boolean = true,
        canRotate: boolean = true,
        canScale: boolean = true,
    ) {
        super();
        this._meshMaterials = [];
        this._isParentFootRight = isParentFootRight;
        this._boundingBox = new Box3();
        this._canScale = canScale;
        this._canMove = canMove;
        this._canRotate = canRotate;
    }

    public loadMesh(element: ArrayBuffer): Promise<Group> {
        // Load the 3DM
        const loader = getRhinoLoader();

        const renderSize = new Vector2();
        ScanController.instance?.renderer.getSize(renderSize);

        return new Promise((resolve, reject) => {
            loader.parse(
                element,
                (object) => {
                    const parentMesh = new Group();
                    parentMesh.renderOrder = 100;
                    parentMesh.scale.setScalar(ScanController.SCALE_TO_M_FACTOR * ScanController.footRatio); // Scale down object (non-resizable objects won't be adapted to foot size)

                    this.add(parentMesh);

                    // Keep reference to the materials and add transparency to them
                    let hasGeometry = false;
                    object.traverse((obj) => {
                        if (obj instanceof Mesh) {
                            obj.material = obj.material.clone(); // Make the material unique
                            obj.material.depthTest = false;
                            obj.material.transparent = true;
                            obj.material.clearcoat = 1; // Increase contrast
                            obj.material.clearcoatRoughness = 0.45;
                            this._meshMaterials.push(obj.material);
                            parentMesh.add(obj);
                            hasGeometry = true;
                        } else if (obj instanceof Line) {
                            obj.material = obj.material.clone(); // Make the material unique
                            const lineGeometry = new LineGeometry();
                            lineGeometry.fromLine(obj as Line);
                            const lineMat = new LineMaterial({
                                dashed: false,
                                resolution: renderSize, // Need to be adapted on resize
                                depthTest: false,
                                transparent: true,
                                linewidth: 3,
                                side: DoubleSide, // Needed for mirroring
                            });
                            lineMat.color = (obj.material as MeshBasicMaterial).color;
                            this._meshMaterials.push(lineMat);
                            parentMesh.add(new Line2(lineGeometry, lineMat));
                            hasGeometry = true;
                        }
                    });
                    if (!hasGeometry) {
                        this.remove(parentMesh);
                        reject(new Error('The object does not have any geometry.'));
                    }

                    this._boundingBox.setFromObject(parentMesh); // Get final bounding box

                    if (this.isParentFootRight) {
                        // Mesh is mirrored on right foot
                        this.mirrorMesh();
                    }

                    resolve(this);
                },
                (error) => reject(error),
            );
        });
    }

    public setHighlighted(highlighted: boolean): void {
        this._meshMaterials.forEach((material) => {
            const mat = material as MeshBasicMaterial;
            if (highlighted) {
                mat.color.b += 0.5;
            } else {
                mat.color.b -= 0.5;
            }
        });
    }

    setOpacity(opacity: number): void {
        this._meshMaterials.forEach((material) => {
            material.opacity = opacity;
        });
    }

    public mirrorMesh() {
        this.children[0].scale.x *= -1;
    }

    clone(recursive?: boolean | undefined) {
        const clone = super.clone(recursive);
        clone._boundingBox = this._boundingBox.clone();
        clone._meshMaterials = [];
        clone.traverse((node) => {
            if (node instanceof Mesh || node instanceof Line2) {
                node.material = node.material.clone();
                clone._meshMaterials.push(node.material);
            }
        });
        clone._canMove = this._canMove;
        clone._canRotate = this._canRotate;
        clone._canScale = this._canScale;

        return clone;
    }

    /**
     * Update materials that need renderer size (lines)
     * @param newRenderSize new size of the renderer
     */
    resize(newRenderSize: Vector2): void {
        this._meshMaterials.forEach((material) => {
            if (material.type === 'LineMaterial') {
                (material as LineMaterial).resolution = newRenderSize;
            }
        });
    }

    dispose(): void {
        MeshUtils.disposeObject(this);
    }
}

export default ElementMesh;
