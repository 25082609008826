<script setup lang="ts">
import OFFSET_Y_VALUE_SCROLL_HEADER from '@/domains/common/services/header.config';
import RouteNameEnum from '@/router/routeNameEnum';
import router from '@/router';
import useAuthStore from '@/domains/authentication/store/auth.store';
import RoleEnum from '@/domains/authentication/typescript/RoleEnum';
import logoAdmin from '@/assets/images/logo-podofit-admin.png';
import logoBase from '@/assets/images/logo-couleur.png';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const isMenuExpanded = ref<Boolean>(true);
const authStore = useAuthStore();

const isMenuDisabled = computed(() => route.name === RouteNameEnum.SelectOffices);
const isAdminErgo = computed(() => authStore.isGranted(RoleEnum.ROLE_ADMIN_ERGO));

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});

const route = useRoute();

const ROUTES_FOR_COMPACT_MENU = [
    RouteNameEnum.Scan,
    RouteNameEnum.Patients,
    RouteNameEnum.Orders,
    RouteNameEnum.AdminUser,
    RouteNameEnum.OrderReport,
];

const isMenuLarge = computed(
    () => isMenuExpanded.value && !ROUTES_FOR_COMPACT_MENU.includes(route.name?.toString() as RouteNameEnum),
);

const logo = computed(() => {
    return isAdminErgo.value ? logoAdmin : logoBase;
});

const handleScroll = () => {
    isMenuExpanded.value = window.scrollY < OFFSET_Y_VALUE_SCROLL_HEADER;
};
</script>
<template>
    <header
        :class="[
            'sticky top-0 z-20 w-full rounded-bl-full rounded-br-full bg-white shadow-drop shadow-shadow transition-all duration-300 ease-in-out',
            isMenuLarge ? 'h-32' : 'h-12',
        ]"
    >
        <div class="m-auto flex h-full max-w-screen-2xl items-center px-8">
            <img :src="logo" alt="Logo de la marque Podofit" class="h-full max-h-16" />
            <div class="flex h-full w-full flex-grow items-center justify-between">
                <nav class="ml-20 flex">
                    <slot name="navSection" :is-menu-disabled="isMenuDisabled" :is-menu-large="isMenuLarge" />
                </nav>
            </div>
            <div class="relative flex h-full items-center justify-center">
                <slot name="menuSection" :is-menu-disabled="isMenuDisabled" :is-menu-large="isMenuLarge" />
            </div>
        </div>

        <div class="absolute -bottom-4 h-4 w-full"></div>
    </header>
</template>
