import App from '@/App.vue';
import router from '@/router';
import i18n from '@/translations';
import { handleRenderError } from '@/domains/common/services/errorHandler';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import '@/assets/base.css';

const app = createApp(App);
app.config.performance = true;
app.config.errorHandler = (err, instance, info) => {
    handleRenderError(err as Error, instance, info, false);
};

// This event is used to reload the page when a preload error occurs
window.addEventListener('vite:preloadError', () => {
    window.location.reload();
});

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    trackComponents: true,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
        Sentry.replayCanvasIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/api.next.podofit.fr/, /^https:\/\/api.app.podofit.fr/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(createPinia());
app.use(router);
app.use(i18n);

app.mount('#app');
