<script setup lang="ts">
import useAuthStore from '@/domains/authentication/store/auth.store';
import IconChevronDown from '@/domains/common/components/icons/IconChevronDown.vue';
import IconChevronUp from '@/domains/common/components/icons/IconChevronUp.vue';
import { computed, ref } from 'vue';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { storeToRefs } from 'pinia';
import type OfficeInterface from '@/domains/office/typescript/OfficeInterface';

const authStore = useAuthStore();
const { isAdminErgo } = storeToRefs(authStore);

interface Props {
    expandedMenu?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    expandedMenu: true,
});

const offices = ref<OfficeInterface[]>(authStore.offices);
const selectedOffice = computed<OfficeInterface | null>(() => authStore.selectedUserOffice);

const onSelectOffice = async (value: OfficeInterface): Promise<void> => {
    const userOffice = authStore.getUserOfficeById(value.id);
    if (userOffice) {
        await authStore.updateUserProfile(userOffice);
    }
    window.location.reload();
};

const hasMultipleOffices = computed(() => {
    return offices.value.length > 1;
});
</script>

<template>
    <Listbox
        :disabled="!hasMultipleOffices"
        :model-value="selectedOffice"
        as="div"
        :class="[
            isAdminErgo && 'bg-primary',
            !isAdminErgo && 'bg-secondary data-[headlessui-state=open]:hover:bg-secondary',
            props.expandedMenu
                ? 'scale-x-1 center absolute top-0 w-auto min-w-full'
                : 'right-0 mb-4 w-72 translate-x-1 scale-x-95',
            'h-8 origin-left transform items-center rounded-b-full transition-all duration-200 ease-in-out ',
            hasMultipleOffices && 'cursor-pointer hover:bg-secondary-dark',
        ]"
        @update:model-value="onSelectOffice"
    >
        <div class="relative h-full">
            <ListboxButton
                v-slot="{ open }"
                :class="[
                    props.expandedMenu && 'pl-4 pr-5',
                    hasMultipleOffices && 'cursor-pointer',
                    'relative flex h-full w-full items-center justify-center rounded-2xl border-transparent text-left capitalize text-white shadow-sm sm:text-sm sm:leading-6',
                ]"
            >
                <span v-if="hasMultipleOffices" class="pointer-events-none inset-y-0 flex items-center justify-center">
                    <IconChevronUp v-if="open" :class="['h-8 w-8', 'font-bold text-white']" aria-hidden="true" />
                    <IconChevronDown v-else :class="['h-8 w-8', 'font-bold text-white']" aria-hidden="true" />
                </span>
                <span class="block truncate font-bold uppercase">{{
                    isAdminErgo ? $t('menu.administration') : selectedOffice?.name
                }}</span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-auto min-w-full overflow-auto rounded-xl bg-white py-1 shadow-lg ring-2 ring-black ring-opacity-10 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-for="office in offices"
                        :key="office.id"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="office"
                    >
                        <li
                            :class="[
                                active || selected ? 'bg-secondary text-white' : 'text-gray-900',
                                'relative m-2 select-none rounded-lg px-8 py-2 text-center',
                            ]"
                        >
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ office.name }}
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>
