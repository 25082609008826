<script setup lang="ts">
import RouteNameEnum from '@/router/routeNameEnum';
import TheHeader from '@/domains/common/components/TheHeader.vue';
import TheNotification from '@/domains/common/components/TheNotification.vue';
import TheLineBackground from '@/domains/common/components/TheLineBackground.vue';
import TheLoader from '@/domains/common/components/TheLoader.vue';
import useAuthStore from '@/domains/authentication/store/auth.store';
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import '@vuepic/vue-datepicker/dist/main.css';
import { storeToRefs } from 'pinia';

const route = useRoute();

const authStore = useAuthStore();

const { isLoadingUser } = storeToRefs(authStore);

const AUTH_LAYOUT_ROUTES = [
    RouteNameEnum.Login,
    RouteNameEnum.ForgottenPassword,
    RouteNameEnum.ResetPassword,
    RouteNameEnum.Invite,
];

const containerClass = computed(() => {
    if (!AUTH_LAYOUT_ROUTES.includes(route.name?.toString() as RouteNameEnum)) {
        return 'pb-3 h-screen flex flex-col overflow-hidden';
    }

    return '';
});

const isHeaderVisible = computed(() => {
    return !AUTH_LAYOUT_ROUTES.includes(route.name?.toString() as RouteNameEnum) && route.name;
});

const shouldDisplayLineBackground = computed(() => {
    return ![AUTH_LAYOUT_ROUTES, RouteNameEnum.Scan].includes(route.name?.toString() as RouteNameEnum);
});
</script>

<template>
    <div :class="[containerClass, 'h-screen']">
        <TheHeader v-if="isHeaderVisible" />
        <TheNotification />
        <TheLoader v-if="isLoadingUser" />
        <TheLineBackground v-if="shouldDisplayLineBackground">
            <RouterView v-if="!isLoadingUser" />
        </TheLineBackground>

        <template v-else>
            <RouterView v-if="!isLoadingUser" />
        </template>
    </div>
</template>
