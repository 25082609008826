import Gizmo from './Gizmo';
import { Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader, Vector3 } from 'three';
import type ElementMesh from '@/3d-app/scan/elementPlacement/meshes/ElementMesh';

/**
 * Gizmo that shows element center and 2D bounds, and is used to set position
 */
class PositionGizmo extends Gizmo {
    private _boundingSquare: Mesh;
    private _centerSquare: Mesh;
    private _highlightMaterial: MeshBasicMaterial;

    public constructor(transformFunction: (pos: Vector3) => void) {
        super(transformFunction);

        // Mesh build
        this._highlightMaterial = new MeshBasicMaterial({
            color: 0xaaaaff,
            transparent: true,
            opacity: 0.0,
            depthWrite: false,
            depthTest: false,
        });

        this._boundingSquare = new Mesh(new PlaneGeometry(1, 1), this._highlightMaterial);
        this._boundingSquare.layers.enable(1);

        // Load texture
        const texture = new TextureLoader().load('/images/position-gizmo.png');

        this._centerSquare = new Mesh(
            new PlaneGeometry(1, 1),
            new MeshBasicMaterial({
                color: 0xccccff,
                transparent: true,
                opacity: 0.9,
                depthWrite: false,
                depthTest: false,
                map: texture,
            }),
        );

        this.add(this._boundingSquare);
        this.add(this._centerSquare);
        this.autoRescaleElement(this._centerSquare);
    }

    public setHighlighted(highlighted: boolean): void {
        super.setHighlighted(highlighted);
        // this._highlightMaterial.opacity = highlighted ? 0.2 : 0.15;
    }

    public update(attachedMesh: ElementMesh): void {
        // Update bounding square
        const bbSize = new Vector3(0, 0, 0);
        attachedMesh.boundingBox.getSize(bbSize);
        this._boundingSquare.scale.set(bbSize.x, bbSize.y, 1);
        this._boundingSquare.position.z = bbSize.z / 2;

        // Update center square
        this._centerSquare.scale.copy(new Vector3(1, 1, 1).divide(attachedMesh.scale));
        this._centerSquare.position.z = bbSize.z / 2;
    }
}

export default PositionGizmo;
