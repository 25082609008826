import ScanMesh from './meshes/ScanMesh';
import SoleMesh from './meshes/SoleMesh';
import ModeManager from './ModeManager';
import ScanWorld from './ScanWorld';
import TemplateMesh from './meshes/TemplateMesh';
import eventBus from '@/3d-app/commons/EventBus';
import { setSelectedFoot, getFormsParameters, getSelectedFoot } from '@/3d-vue-api/storeAPI';
import { Box3, Object3D, Raycaster, Vector2, Vector3, type Event } from 'three';
import * as TWEEN from 'three/examples/jsm/libs/tween.module';
import type TWorldParams from '@/3d-app/commons/world/TWorldParams';
import type ScanFilesBlobInterface from '@/domains/scan/typescript/interfaces/ScanFilesBlobInterface';
import type AnimatedCamera from '@/3d-app/commons/camera/AnimatedCamera';

class ScanController extends ScanWorld {
    private static _instance: ScanController;

    private _leftScanMesh: ScanMesh;

    private _leftSoleMesh: SoleMesh;

    private _leftTemplateMesh: TemplateMesh;

    private _rightScanMesh: ScanMesh;

    private _rightSoleMesh: SoleMesh;

    private _rightTemplateMesh: TemplateMesh;

    private _isRightFoot: boolean;

    private _isOtherFootVisible: boolean;

    private _modeManager: ModeManager;

    private _raycaster: Raycaster;

    // This value was determined by comparing differences, and is currently the best average correction
    public static readonly XOFFSET: number = -4.5;

    public static readonly SCALE_TO_M_FACTOR = 0.001;

    /**
     * Get ratio between the current foot size and the reference size (52)
     */
    public static get footRatio(): number {
        return ScanController.footSize / 52;
    }

    public static get footSize(): number {
        const { size } = getFormsParameters().currentOrder.value;
        if (size) {
            return size;
        }
        return 42;
    }

    public static getSoleThickness(isRightSole: boolean): number {
        let soleThickness;
        if (isRightSole) {
            ({ soleThickness } = getFormsParameters().soleParametersRight.value);
        } else {
            ({ soleThickness } = getFormsParameters().soleParametersLeft.value);
        }
        if (soleThickness) {
            return Number.parseFloat(soleThickness);
        }
        return 0.3;
    }

    public static getHeelDepth(isRightHeel: boolean): number {
        let addedThicknessHeel;
        if (isRightHeel) {
            ({ addedThicknessHeel } = getFormsParameters().soleParametersRight.value);
        } else {
            ({ addedThicknessHeel } = getFormsParameters().soleParametersLeft.value);
        }
        if (addedThicknessHeel) {
            return Number.parseFloat(addedThicknessHeel);
        }
        return 0.1;
    }

    public static getPattern(): number {
        return +(getFormsParameters().soleConfiguration.value.pattern ?? '0');
    }

    public static getCuttingLength(): number {
        return getFormsParameters().currentOrder.value.cuttingLength;
    }

    public updatePattern(): void {
        this._leftTemplateMesh.dispose();
        this._leftTemplateMesh.loadTemplate(false).then((template) => {
            this._scene.add(template);
        });
        this._rightTemplateMesh.dispose();
        this._rightTemplateMesh.loadTemplate(true).then((template) => {
            this._scene.add(template);
        });
    }

    public hasScan(isRight: boolean) {
        return this.getScanMesh(isRight).mesh.children.length > 0;
    }

    /**
     * Get current scan mesh
     */
    get currentScanMesh(): ScanMesh {
        return this.getScanMesh(this._isRightFoot);
    }

    getScanMesh(isRightScan: boolean): ScanMesh {
        if (isRightScan) {
            return this._rightScanMesh;
        }

        return this._leftScanMesh;
    }

    /**
     * Get current sole mesh
     */
    get currentSoleMesh(): SoleMesh {
        return this.getSoleMesh(this._isRightFoot);
    }

    getSoleMesh(isRightSole: boolean): SoleMesh {
        if (isRightSole) {
            return this._rightSoleMesh;
        }

        return this._leftSoleMesh;
    }

    /**
     * Get current template mesh
     */
    get currentTemplateMesh(): TemplateMesh {
        return this.getTemplateMesh(this._isRightFoot);
    }

    getTemplateMesh(isRightTemplate: boolean): TemplateMesh {
        if (isRightTemplate) {
            return this._rightTemplateMesh;
        }

        return this._leftTemplateMesh;
    }

    get modeManager(): ModeManager {
        return this._modeManager;
    }

    get camera(): AnimatedCamera {
        return this._camera as AnimatedCamera;
    }

    get soleThickness(): number {
        return this.soleThickness;
    }

    get heelDepth(): number {
        return this.heelDepth;
    }

    get isRightFoot(): boolean {
        return this._isRightFoot;
    }

    set isRightFoot(isRightFoot: boolean) {
        if (this._isRightFoot === isRightFoot) {
            return;
        }
        this._isRightFoot = isRightFoot;

        // Notify the change
        eventBus.emit('selected-foot-updated', this._isRightFoot);
    }

    get isOtherFootVisible(): boolean {
        return this._isOtherFootVisible;
    }

    set isOtherFootVisible(isVisible: boolean) {
        this._isOtherFootVisible = isVisible;
        this.updateFootVisibility();
    }

    public static get instance(): ScanController | undefined {
        if (!ScanController._instance) {
            return undefined;
        }
        return ScanController._instance as ScanController;
    }

    constructor(params: TWorldParams) {
        super(params);

        ScanController._instance = this;

        this._raycaster = new Raycaster();

        this._isRightFoot = getSelectedFoot();
        this._isOtherFootVisible = true;

        // Create the empty 3D scans
        this._leftScanMesh = new ScanMesh();
        this._rightScanMesh = new ScanMesh();

        // Create empty soles
        this._leftSoleMesh = new SoleMesh();
        this._rightSoleMesh = new SoleMesh();

        // Load template meshes
        this._leftTemplateMesh = new TemplateMesh();
        this._rightTemplateMesh = new TemplateMesh();

        Promise.all([
            this._leftTemplateMesh.loadTemplate(false).then((template) => {
                this._scene.add(template);
            }),
            this._rightTemplateMesh.loadTemplate(true).then((template) => {
                this._scene.add(template);
            }),
        ])
            .then(() => {
                this.camera.homeCamera(
                    ScanController.footSize * ScanController.SCALE_TO_M_FACTOR * ScanWorld.HOME_CAMERA_DISTANCE,
                    new Vector3(0, ScanWorld.Y_OFFSET_CAMERA * ScanController.footSize, 0),
                    false,
                );
                eventBus.emit('scene-ready'); // Sent when both templates are loaded
            })
            .catch(console.error);

        // Initialize all modes
        this._modeManager = new ModeManager();

        // Focus camera to selected foot
        this.updateSelectedFoot();

        // Setup double click event
        this.canvas?.addEventListener('dblclick', (event: Event) => this.onMouseDoubleClickEvent(event as MouseEvent));
        this.canvas?.addEventListener('mousedown', (event: Event) => event.preventDefault()); // Prevent selection of UI elements

        // Update renderer and start render loop
        this.resize();
        this._mainLoop.start();
    }

    /**
     * Update foot size and adapt current data
     * @param previousSize the previous foot size (to compute size delta)
     */
    public updateFootSize(previousSize: number): void {
        this._leftTemplateMesh.readaptSize(false);
        this._rightTemplateMesh.readaptSize(true);
        this._modeManager.elementMode.readaptElementsToFootSize(previousSize);

        const target = new Vector3(
            ScanController.getFootOffset(this.isRightFoot),
            ScanWorld.Y_OFFSET_CAMERA * ScanController.footSize,
            this.currentSoleMesh.mesh.position.z,
        );
        this.camera.setTargetKeepingOrbit(target);

        const deltaSize = ScanController.footSize / previousSize;
        this.camera.setRadius(this.camera.radius * deltaSize);
    }

    /**
     * Update camera global to focus current foot (with animation)
     * Used when the rotation is locked
     */
    public static focusCurrentFoot(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        const target = new Vector3(
            ScanController.getFootOffset(scanController.isRightFoot),
            ScanWorld.Y_OFFSET_CAMERA * ScanController.footSize,
            scanController.currentSoleMesh.mesh.position.z,
        );

        scanController.camera.setSphericalCoordinates({
            theta: 0,
            phi: Math.PI / 2,
            animate: true,
        });
        scanController.camera.setTargetKeepingOrbit(target, true);
    }

    private onMouseDoubleClickEvent(event: MouseEvent): void {
        this._raycaster.setFromCamera(
            new Vector2(
                (event.offsetX / this.containerSize.width) * 2 - 1,
                -(event.offsetY / this.containerSize.height) * 2 + 1,
            ),
            this.camera,
        );

        let raycastDest: Object3D<Event>;
        if (this.currentSoleMesh.getIsVisible()) {
            let soleToCheck = this._rightSoleMesh;
            if (this.isRightFoot) {
                soleToCheck = this._leftSoleMesh;
            }
            raycastDest = soleToCheck.soleGeometry;
        } else {
            let templateToCheck = this._rightTemplateMesh;
            if (this.isRightFoot) {
                templateToCheck = this._leftTemplateMesh;
            }
            raycastDest = templateToCheck.raycastMesh;
        }
        const hit = this._raycaster.intersectObject(raycastDest); // Check only other foot

        if (hit.length > 0) {
            // If other sole is under mouse, select it
            this.isRightFoot = !this.isRightFoot;
            setSelectedFoot(this.isRightFoot);
        }
    }

    /**
     * Update currently selected foot by highlighting it and focusing the camera on it
     */
    public updateSelectedFoot(): void {
        // Gray out other template
        this._isRightFoot = !this._isRightFoot;
        this.currentTemplateMesh.setGrayedOut(true);
        this._isRightFoot = !this._isRightFoot;
        this.currentTemplateMesh.setGrayedOut(false);

        this.updateFootVisibility();

        // Change camera target
        if (!this.modeManager.elementMode.enabled && !this.modeManager.alignPointMode.enabled) {
            // Highlight selected foot
            this.updateHighlighted([this.currentSoleMesh.mesh]);
            this.camera.setTargetKeepingOrbit(
                new Vector3(
                    ScanController.getFootOffset(this._isRightFoot),
                    ScanWorld.Y_OFFSET_CAMERA * ScanController.footSize,
                    0,
                ),
                true,
            );
        }
    }

    /**
     * Load the scan of the currently selected foot and add it to the scene
     * @param scan the object containing all blobs (obj, mtl and jpg)
     * @param flip true to flip the scan for negative scans (default false)
     */
    loadScan(scan: ScanFilesBlobInterface, flip: boolean = false): void {
        this.modeManager.alignPointMode.dispose(false); // Remove previous align points
        this.modeManager.deactivateCurrentMode();
        this.currentScanMesh.dispose();
        this.currentScanMesh.loadScan(this._isRightFoot, scan).then((currentScan) => {
            if (flip) {
                currentScan.rotateY(Math.PI);
            }
            this.scene.add(currentScan);

            // Put foot on ground otherwise its centered using gravity center
            const bbox = new Box3().setFromObject(currentScan); // Get bounding box
            const { pivot } = this.currentScanMesh;
            pivot.position.set(0, 0, (bbox.max.z - bbox.min.z) / 2);
            this.modeManager.alignPointMode.recomputeScanPosition(this._isRightFoot);
        });
    }

    /**
     * Load the 2d scan of the currently selected foot and add it to the scene
     * @param image the blobs containing the png image of the 2d scan
     * @param dpi the image resolution
     * @param flip true to flip the scan for negative scans (default false)
     */
    load2dScan(image: Blob, dpi: number, flip: boolean = false): void {
        this.modeManager.alignPointMode.dispose(false); // Remove previous align points
        this.modeManager.deactivateCurrentMode();
        this.currentScanMesh.dispose();
        this.currentScanMesh.load2dScan(this._isRightFoot, image, dpi).then((currentScan) => {
            if (flip) {
                currentScan.rotateY(Math.PI);
            }
            this.scene.add(currentScan);
            this.modeManager.alignPointMode.recomputeScanPosition(this._isRightFoot);
        });
    }

    /**
     * Change scan opacity
     * @param opacity value of opacity, from 0 (full transparent) to 1 (full opaque)
     */
    setScanOpacity(opacity: number): void {
        this._leftScanMesh.setOpacity(opacity);
        this._rightScanMesh.setOpacity(opacity);
    }

    /**
     * Change sole opacity
     * @param opacity value of opacity, from 0 (full transparent) to 1 (full opaque)
     */
    setSoleOpacity(opacity: number): void {
        this._leftSoleMesh.setOpacity(opacity);
        this._rightSoleMesh.setOpacity(opacity);
    }

    /**
     * Toggle sole visibility on or off
     * @param isVisible true for visible, false for transparent
     */
    setSoleVisible(isVisible: boolean): void {
        this._leftSoleMesh.setIsVisible(isVisible);
        this._rightSoleMesh.setIsVisible(isVisible);
    }

    /**
     * Toggle template visibility on or off
     * @param isVisible true for visible, false for transparent
     */
    setTemplateVisible(isVisible: boolean): void {
        this._leftTemplateMesh.setIsVisible(isVisible);
        this._rightTemplateMesh.setIsVisible(isVisible);
    }

    /**
     * Load or reload the sole model
     * @param sole ArrayBuffer containing the 3dm of the sole
     * @param isRightSole true is the given sole is the right one (false by default)
     * @returns a promise that is resolved when the sole is loaded and added to the scene
     */
    loadSole(sole: ArrayBuffer, isRightSole: boolean = false): Promise<null> {
        const soleToLoad = isRightSole ? this._rightSoleMesh : this._leftSoleMesh;
        return soleToLoad.loadSole(isRightSole, sole).then((soleMesh) => {
            this._scene.add(soleMesh);
            return null;
        });
    }

    private updateFootVisibility(): void {
        // Update soles and scans visibility
        this._leftScanMesh.updateVisibility();
        this._leftSoleMesh.updateVisibility();
        this._rightScanMesh.updateVisibility();
        this._rightSoleMesh.updateVisibility();
        this._leftTemplateMesh.updateVisibility();
        this._rightTemplateMesh.updateVisibility();
    }

    /**
     * Return foot offset distance in cm (negative for left foot)
     * @param isRightFoot true for right foot, false otherwise
     */
    static getFootOffset(isRightFoot: boolean): number {
        return ScanController.footRatio * 75 * (isRightFoot ? 1 : -1) * ScanController.SCALE_TO_M_FACTOR;
    }

    render(): void {
        // Render other custom objects
        TWEEN.update();
        super.render();
    }

    resize(): void {
        super.resize();
        const newSize = new Vector2();
        this._renderer.getSize(newSize);
        this._leftTemplateMesh.resize(newSize);
        this._rightTemplateMesh.resize(newSize);
        this._modeManager.elementMode.resize(newSize);
    }

    dispose(): void {
        // Dispose other custom objects
        this._leftScanMesh.dispose();
        this._rightScanMesh.dispose();
        this._leftSoleMesh.dispose();
        this._rightSoleMesh.dispose();
        this._leftTemplateMesh.dispose();
        this._rightTemplateMesh.dispose();
        this.modeManager.dispose();
        super.dispose();
        // @ts-ignore
        ScanController._instance = undefined;
    }
}

export default ScanController;
