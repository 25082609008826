import type { MillingMaterialEnum } from '@/domains/scan/typescript/enums/MillingMaterialEnum';
import type { PrintingMaterialEnum } from '@/domains/scan/typescript/enums/PrintingMaterialEnum';
import type PatientInterface from '@/domains/patient/typescript/PatientInterface';

enum OrderStatusEnum {
    IN_CREATION, // status par défaut dès la création
    ASK_FOR_CALCUL, // A de la demande de calcul
    CALCULATED, // Au retour d'une semelle 3D
    TRANSMITTED, // Au clic sur la fleche du listing des commandes ('envoyer')
    VALIDATED, // Reçu par orthotix
    IMPROPER, // Maintenu par ergo (excel)
    PLANNED, // Maintenu par ergo  (excel)
    PRODUCED, // Maintenu par ergo  (excel)
    SHIPPED, // Maintenu par ergo  (excel)
    CANCELLED, // Maintenu par ergo  (excel)
    ARCHIVED, // Statut d'archive
}

interface OrderInterface {
    id?: string;
    number?: number;
    patient: Pick<PatientInterface, '@id' | 'id' | 'lastName' | 'firstName'>;
    consultant: string;
    creationDate: Date;
    status: OrderStatusEnum;
    comment?: string;
    privateNote?: string;
    size?: number;
    glued?: boolean;
    lowCut?: boolean;
    sanded: boolean;
    cuttingLength: number;
    manufacture: number;
    material?: PrintingMaterialEnum | MillingMaterialEnum;
    coveringMaterial: number;
    createdAt?: string;
    updatedAt?: string;
    orderNumber?: string;
    doctor?: string;
    orderParametersFile?: string;
    sceneFile?: string;
    batFile?: string;
    leftSole?: string;
    leftSoleFrontScreenshotFile?: string;
    leftSoleSideScreenshotFile?: string;
    rightSole?: string;
    rightSoleFrontScreenshotFile?: string;
    rightSoleSideScreenshotFile?: string;
    leftScan2d?: string;
    leftScanObj?: string;
    leftScanJpg?: string;
    leftScanMtl?: string;
    rightScan2d?: string;
    rightScanObj?: string;
    rightScanJpg?: string;
    rightScanMtl?: string;
}

export { OrderStatusEnum };
export type { OrderInterface };
