<script setup lang="ts">
import useAuthStore from '@/domains/authentication/store/auth.store';
import UserHeader from '@/domains/common/components/UserHeader.vue';
import AdminHeader from '@/domains/common/components/AdminHeader.vue';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();

const { isAdminErgo } = storeToRefs(authStore);
</script>

<template>
    <AdminHeader v-if="isAdminErgo" />
    <UserHeader v-else />
</template>
